import * as React from "react";
import {forwardRef} from "react";

import styles from './Calendario.module.css';
import CalendarioProps from './CalendarioProps';
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";

const Calendario = forwardRef((props: CalendarioProps, inputRef?: any) => {
    addLocale('br', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });
    return (
        <>
            <div className={`${styles.espacamento} p-fluid grid`}>
                <div className={props.fullSize ? "field col-12 md:col-12": "field col-12 md:col-4"}>
                    <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                    <span>
                        <Calendar id={props.id} value={props.value ? props.value : ''}
                                  ref={inputRef !== undefined ? inputRef : null}
                                  minDate={props.minDate}
                                  tooltip={props.tooltip ? props.tooltip : undefined}
                                  tooltipOptions={props.tooltipPosition ? {position: props.tooltipPosition} : undefined}
                                  aria-describedby={`${props.id}-message`}
                                  style={{width: `${props.size}`}}
                                  required={props.required}
                                  placeholder={props.placeholder}
                                  dateFormat={"dd/mm/yy"}
                                  showIcon={true}
                                  locale={"br"}
                                  onSelect={props.onSelect}
                                  disabled={props.disabled}
                                  onBlur={props.onBlurHandler}
                                  onFocus={props.onFocusHandler}
                                  onChange={props.onChangeHandler}/>
                    </span>
                </div>
            </div>
        </>
    );
});

export default Calendario;