import ListView from "../../component/ListView";
import {Column} from "primereact/column";
import Restaurante from "../../model/Restaurante";
import CpfCnpjUtils from "../../utils/CpfCnpjUtils";
import * as React from "react";
import {useContext, useRef, useState} from "react";
import {MenuItem} from "primereact/menuitem";
import {PrimeIcons} from "primereact/api";
import {useNavigate} from 'react-router-dom';
import rest from "../../utils/EnvConfig";
import useToken from "../../hook/useToken";
import {TipoUsuario} from "../../model/TipoUsuario";
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import styles from "./Restaurante.module.css";
import {RestauranteStatus} from "../../model/RestauranteStatus";
import {Ajax} from "../../utils/Ajax";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";

function RestauranteList() {
    const {token} = useToken();
    const {error, info} = useContext(MensagemContext) as MensagemContextType;
    const navigate = useNavigate();
    const fotosMenuRef = useRef([]);
    const cardapiosMenuRef = useRef<Menu>(null);
    const [loadingAtivarInativarButton, setLoadingAtivarInativarButton] = useState(false);
    fotosMenuRef.current = [];

    const cpfCnpjTemplate = (restaurante: Restaurante) => {
        return CpfCnpjUtils.formatarCampoString(restaurante.cpfCnpj);
    }

    const obterRecursoCorreto = (): string | undefined => {
        if (token && token.tipo === TipoUsuario.ADMINISTRADOR) {
            return `${rest.restaurante}/${rest.obterTodos}`;
        }

        if (token && token.tipo === TipoUsuario.CLIENTE) {
            return `${rest.restaurante}/${rest.restaurantesPorUsuario}${token.usuarioUuid}`;
        }

        return "";
    }

    const itensCardapiosMenuBotoesExtras: MenuItem[] = [
        {
            label: 'Cardápio de comida',
            icon: 'pi pi-file-edit',
            command: () => {
                navigate(`/restaurante/cardapio/comida`);
            }
        },
        {
            label: 'Cardápio de bebida',
            icon: 'pi pi-file-edit',
            command: () => {
                navigate(`/restaurante/cardapio/bebida`);
            }
        }
    ];

    const botoesPrincipaisExtra = [
        (
            <>
                <Menu model={itensCardapiosMenuBotoesExtras} className={styles.menuFotos} popup ref={cardapiosMenuRef} />
                <Button icon="pi pi-pen-to-square" className={`${styles.espacamentoBotoes} mr-2`} raised size={"large"}
                        label={"Cardápios"}
                        // @ts-ignore
                        title={"Cadastro de cardápios"} onClick={(event: any) => cardapiosMenuRef.current.toggle(event)}
                        severity="success" />
            </>
        )];

    const ativarInativarRestauranteCliente = async (entidade: Restaurante) => {
        setLoadingAtivarInativarButton(true);
        await Ajax.of().postRegistroCustom(`${rest.server}${rest.restaurante}/ativar/inativar/${entidade.uuid}`, {}, () => {
            info("Status do restaurante atualizado com sucesso.");
            window.location.href = "/restaurante";
        }, (err: Error) => {
            error(err.message);
            setLoadingAtivarInativarButton(false);
        });
    };

    const botoesExtra = (entidade: Restaurante, rowIndex?: number): React.JSX.Element[] => {
        return [
            (
                <>
                    {token.tipo === TipoUsuario.CLIENTE && (
                        <Button icon={entidade.status === RestauranteStatus.ATIVO ? PrimeIcons.BAN : PrimeIcons.CHECK} className={`${styles.espacamentoBotoes} mr-2`}
                                title={entidade.status === RestauranteStatus.ATIVO ? "Inativar restaurante" : "Ativar restaurante"}
                                onClick={() => ativarInativarRestauranteCliente(entidade)}
                                loading={loadingAtivarInativarButton}
                                raised severity="warning" />
                    )}

                    <Button icon={PrimeIcons.CAMERA} className={`${styles.espacamentoBotoes} mr-2`} raised
                            disabled={desabilitarEditar(entidade)}
                            title={"Envio de fotos e logo do restaurante"} onClick={() => navigate(`/restaurante/imagem/${entidade.uuid}`)}
                            severity="success" />
                </>
            )];
    }

    const desabilitarEditar = (entidade: Restaurante): boolean => {
        if (token.tipo === TipoUsuario.ADMINISTRADOR) {
            return false;
        }
        return entidade.status !== RestauranteStatus.ATIVO;
    }

    const visualizarExcluir = (entidade: Restaurante): boolean => {
        return token.tipo !== TipoUsuario.CLIENTE;
    }

    return (
        <>
            <ListView title={"Restaurante"}
                      botoesAdicionais={botoesExtra}
                      botoesPrincipaisAdicionais={botoesPrincipaisExtra}
                      disableEditar={desabilitarEditar}
                      visibleExcluir={visualizarExcluir}
                      key={"restauranteKey"}
                      restCustom={obterRecursoCorreto()}
                      id={`${rest.restaurante}`}>

                {token.tipo === TipoUsuario.ADMINISTRADOR ?
                    [
                        <Column field="nomeEstabelecimento" header="Nome do estabelecimento" sortable/>,
                        <Column field="razaoSocial" header="Razão social" sortable/>,
                        <Column field="cpfCnpj" header="CPF/CNPJ" body={cpfCnpjTemplate} sortable/>,
                        <Column field="status" header="Status" sortable/>
                    ]
                    :
                    [
                        <Column field="nomeEstabelecimento" header="Nome" sortable/>,
                        <Column field="enderecoCompleto" header="Endereço" sortable/>,
                        <Column field="status" header="Status" sortable/>
                    ]
                }
            </ListView>
        </>
    );
}

export default RestauranteList;