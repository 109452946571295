import {Entidade} from "./Entidade";
import RestauranteTipoRestaurante from "./RestauranteTipoRestaurante";
import RestauranteDiferencial from "./RestauranteDiferencial";
import RestauranteHorarioFuncionamento from "./RestauranteHorarioFuncionamento";
import RestauranteDiaDiferencial from "./RestauranteDiaDiferencial";
import {RestauranteStatus} from "./RestauranteStatus";

export default class Restaurante extends Entidade {

    usuarioUuid?: string;
    status?: RestauranteStatus;
    nomeEstabelecimento?: string;
    razaoSocial?: string;
    cpfCnpj?: string;
    telefone?: string;
    whatsapp?: string;
    instagram?: string;
    facebook?: string;
    enderecoCompleto?: string;
    logo?: string;
    latitude?: number;
    longitude?: number;

    tiposRestaurantes?: RestauranteTipoRestaurante[] = [];
    diferenciais?: RestauranteDiferencial[] = [];
    horariosFuncionamento?: RestauranteHorarioFuncionamento[] = [];
    horariosFuncionamentoDiferencial?: RestauranteDiaDiferencial[] = [];

    normalizarAtributosInternos = (): void => {
        this.tiposRestaurantes?.forEach(t => t.restaurante = this);
    }

}