import ListView from "../../component/ListView";
import {Column} from "primereact/column";
import * as React from "react";
import {useNavigate} from 'react-router-dom';
import rest from "../../utils/EnvConfig";
import useToken from "../../hook/useToken";
import {TipoUsuario} from "../../model/TipoUsuario";
import Restaurante from "../../model/Restaurante";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import styles from "./Restaurante.module.css";

function RestauranteCardapioBebidaList() {
    const {token} = useToken();
    const navigate = useNavigate();

    const obterRecursoCorreto = (): string | undefined => {
        if (token && token.tipo === TipoUsuario.ADMINISTRADOR) {
            return `${rest.restauranteCardapioBebida}/${rest.obterTodos}`;
        }

        if (token && token.tipo === TipoUsuario.CLIENTE) {
            return `${rest.restauranteCardapioBebida}/${rest.cardapiosBebidaPorUsuario}${token.usuarioUuid}`;
        }

        return "";
    }

    const botoesExtra = (entidade: Restaurante, rowIndex?: number): React.JSX.Element[] => {
        return [
            (
                <>
                    <Button icon={PrimeIcons.CAMERA} className={`${styles.espacamentoBotoes} mr-2`} raised
                            title={"Envio de fotos"} onClick={(event: any) => navigate(`/restaurante/cardapio/bebida/imagem/${entidade.uuid}`)}
                            severity="success" />
                </>
            )];
    }

    return (
        <>
            <ListView title={"Restaurante - Cardápio de bebida"}
                      restCustom={obterRecursoCorreto()}
                      botoesAdicionais={botoesExtra}
                      visibleExcluir={(entidade) => token.tipo === TipoUsuario.ADMINISTRADOR}
                      id={`${rest.restauranteCardapioBebida}`}>
                <Column field="nomeCardapio" header="Nome do cardápio" sortable/>
            </ListView>
        </>
    );
}

export default RestauranteCardapioBebidaList;