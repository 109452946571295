import React, {useContext, useEffect, useRef, useState} from "react";

import EditView from "../../component/EditView";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import useToken from "../../hook/useToken";
import {useNavigate} from "react-router-dom";
import Banner from "../../model/Banner";
import rest from "../../utils/EnvConfig";
import {RadioButton} from "primereact/radiobutton";
import {BannerTipo} from "../../model/BannerTipo";
import styles from "./Banner.module.css";
import Texto from "../../component/form/texto/Texto";
import {FileUpload, FileUploadFile, FileUploadSelectEvent} from "primereact/fileupload";
import Calendario from "../../component/form/calendario/Calendario";
import Combo from "../../component/form/combo/Combo";
import Restaurante from "../../model/Restaurante";
import {Ajax} from "../../utils/Ajax";
import {TipoUsuario} from "../../model/TipoUsuario";
import {Fieldset} from "primereact/fieldset";
import {Image} from "primereact/image";
import moment, {Moment} from "moment/moment";
import {isNumber} from "chart.js/helpers";
import {Button} from "primereact/button";

function BannerEdit() {
    const {error, info} = useContext(MensagemContext) as MensagemContextType;
    const [entidade, setEntidade] = useState<Banner>(new Banner());
    const [imagemSelecionada, setImagemSelecionada] = useState<FileUploadFile>();
    const {token} = useToken();
    const navigate = useNavigate();
    const [restaurantes, setRestaurantes] = useState<Restaurante[]>([]);
    const uploadRef = useRef<any>();
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

    useEffect(() => {
        Ajax.of()
            .error(error)
            .obterTodosCustom(obterRecursoRestaurantesCorreto(), (data) => {
                setRestaurantes(data);
            });
    }, []);

    useEffect(() => {
        if (!entidade.id) {
            if (entidade.tipo && entidade.dataInicial && entidade.dataFinal) {
                atualizarValorBanner();
            }
        }
    }, [entidade.tipo, entidade.dataInicial, entidade.dataFinal]);

    const obterRecursoRestaurantesCorreto = (): string | undefined => {
        if (token && token.tipo === TipoUsuario.ADMINISTRADOR) {
            return `${rest.restaurante}/${rest.obterTodos}`;
        }

        if (token && token.tipo === TipoUsuario.CLIENTE) {
            return `${rest.restaurante}/${rest.restaurantesPorUsuario}${token.usuarioUuid}`;
        }

        return "";
    }

    const editEntidade = (banner: Banner) => {
        setEntidade(banner);
        if (banner.dataInicial && banner.dataFinal) {
            const novaDataInicial: Moment = moment(banner.dataInicial, "YYYY-MM-DD");
            const novaDataFinal: Moment = moment(banner.dataFinal, "YYYY-MM-DD");
            setEntidade({ ...banner, dataInicial: novaDataInicial.toDate(),
                                             dataFinal: novaDataFinal.toDate() });
        }
    }

    const entidadeOnChangeHandler = (e: any) => {
        setEntidade({ ...entidade, [e.target.id]: e.target.value });
    }

    const tipoOnChangeHandler = (e: any) => {
        setEntidade({ ...entidade, tipo: e.value });
    }

    const atualizarValorBanner = async () => {
        await Ajax.of()
            .error(error)
            .postRegistroCustom(`${rest.server}${rest.banner}/${rest.bannerCalcularValor}`, entidade, (data) => {
                setEntidade({ ...entidade, valor: data.valor });
            });
    }

    const salvarCompleto = async (endLoadingFunc: () => void) => {
        await efetuarPagamentoMercadoPago(async () => {
           await salvarBanner(async () => {
                await uploadBanner(async () => {
                    info("Banner cadastrado com sucesso");
                    navigate("/banner");
                });
           });
        }, async () => {
            console.log("faz alguma coisa se o pagamento nao tiver sucesso");
        });
    }

    const efetuarPagamentoMercadoPago = async (onSuccess: () => Promise<void>,
                                               onError: () => Promise<void>) => {
        console.log("faz todo o paranaue e executa o onSucess");
        await onSuccess();
    }

    const salvarBanner = async (onSucess: () => Promise<void>) => {
        await Ajax.of()
            .salvarRegistro(`${rest.banner}`,
                entidade, async (entidade: any) => {
                    setEntidade({...entidade, uuid: entidade.uuid});
                    await onSucess();
                });
    }

    const uploadBanner = async (onSucess: () => Promise<void>) => {
        if (entidade.tipo === BannerTipo.INTERNO) {
            uploadRef.current.upload();
        }
        await onSucess();
    }

    const validar = async (): Promise<boolean> => {
        if (entidade.tipo === BannerTipo.INTERNO && !entidade.id) {
            if (uploadRef.current && uploadRef.current.getFiles().length === 0) {
                error("Nenhum arquivo de banner foi informado.");
                return false;
            }
        }

        if (entidade.tipo === BannerTipo.EXTERNO && !entidade.fraseExterno) {
            error("Informe uma frase para o banner.");
            return false;
        }

        if (!entidade.restaurante) {
            error("Informe um restaurante.");
            return false;
        }

        if (!entidade.dataInicial) {
            error("Informe uma data para iniciar a campanha.");
            return false;
        }

        if (!entidade.dataFinal) {
            error("Informe uma data para finalizar a campanha.");
            return false;
        }

        if (!entidade.valor || entidade.valor === 0.0) {
            error("O valor do banner não foi calculado.");
            return false;
        }

        return true;
    }

    const isDesabilitarAoEditar = (): boolean => {
        return token.tipo === TipoUsuario.CONSUMIDOR || isNumber(entidade.id);
    }

    const limparImagemSelecionada = () => {
        if (uploadRef.current) {
            uploadRef.current.clear();
            setImagemSelecionada(undefined);
        }
    }

    const handleImageLoad = (e: any) => {
        const { naturalHeight, naturalWidth } = e.target;
        if (!isBannerProportion(naturalWidth, naturalHeight)) {
            error("O banner precisa ter uma proporção semelhante a 800 x 400.");
            limparImagemSelecionada();
        }
    };

    const isBannerProportion = (width: number, height: number): boolean => {
        const bannerAspectRatio: number = 800 / 400;
        const imageAspectRatio: number = width / height;
        const ratioTolerancia: number = 0.3;
        return Math.abs(imageAspectRatio - bannerAspectRatio) <= ratioTolerancia;
    }

    return (
        <>
            <EditView title={"Banner"}
                      id={`${rest.banner}`}
                      entidade={entidade}
                      onSalvarHandler={salvarCompleto}
                      sairAoSalvar={false}
                      onValidationHandler={validar}
                      entidadeUseStateFunction={editEntidade}>

                <Fieldset legend={"Tipo do banner"}>
                    <div className={`${styles.espacamento} p-fluid grid`}>
                        <div className="field col-12 md:col-12">
                            <label className={styles.label} htmlFor="tipoInterno">* Exibição</label>
                            <span>
                                <div className="card flex">
                                    <div className="flex flex-wrap gap-3">
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="tipoInterno" name="tipo" value={BannerTipo.INTERNO}
                                                         disabled={isDesabilitarAoEditar()}
                                                         onChange={(e) => tipoOnChangeHandler(e)}
                                                         checked={entidade.tipo === BannerTipo.INTERNO}/>
                                            <label htmlFor="tipoInterno" className="ml-2">Página do restaurante</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="tipoExterno" name="tipo" value={BannerTipo.EXTERNO}
                                                         disabled={isDesabilitarAoEditar()}
                                                         onChange={(e) => tipoOnChangeHandler(e)}
                                                         checked={entidade.tipo === BannerTipo.EXTERNO}/>
                                            <label htmlFor="tipoExterno" className="ml-2">Página principal</label>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>

                    <div className="field col-12 md:col-12">
                        {entidade.tipo === BannerTipo.EXTERNO && (
                            <>
                                <b>* O título e a frase do banner pode sofrer alterações para se adequar com o design.</b>
                                <br/>
                                <br/>
                                <Texto id={"tituloFraseExterno"} label={"* Título do banner"}
                                       maxLength={25} fullSize={true}
                                       onChangeHandler={entidadeOnChangeHandler}
                                       value={entidade.tituloFraseExterno}/>

                                <Texto id={"fraseExterno"} label={"* Frase do banner"}
                                       maxLength={60} fullSize={true}
                                       onChangeHandler={entidadeOnChangeHandler}
                                       value={entidade.fraseExterno}/>
                            </>
                        )}

                        {entidade.tipo === BannerTipo.INTERNO && (
                            <>
                                <b>* O arquivo deve ser uma imagem (jpg ou png) de até 1 MB.</b>
                                <br/>
                                <b>* A imagem precisa obedecer a proporção de 800 x 400 com uma leve tolerância de tamanho.</b>
                                <br/>
                                <br/>
                                <div>
                                    <FileUpload mode={"basic"} ref={uploadRef} name="imagem"
                                                multiple={false} style={{position: "relative", float: "left", paddingRight: "10px"}}
                                                disabled={isDesabilitarAoEditar()}
                                                url={`${rest.server}${rest.banner}/${rest.bannerUpload}${entidade.uuid}`}
                                                accept="image/*" maxFileSize={1048576} // 1 MB
                                                chooseLabel={"Escolher uma imagem"}
                                                onValidationFail={(e: File) => error("Imagem inválida")}
                                                onSelect={(e: FileUploadSelectEvent) => setImagemSelecionada(e.files[0])}
                                                auto={false}/>

                                    {imagemSelecionada && (
                                        <Button icon="pi pi-trash" severity="danger" raised
                                                onClick={(e: any) => limparImagemSelecionada()} />
                                    )}
                                </div>

                                {imagemSelecionada && (
                                    <>
                                        <br/>
                                        <Image src={imagemSelecionada.objectURL} onLoad={handleImageLoad} width={"300"} height={"120"}/>
                                    </>
                                )}
                            </>
                        )}

                        {entidade.tipo === BannerTipo.INTERNO && entidade.id && (
                            <>
                                <br />
                                <br />
                                <br />
                                <Image
                                    src={`${rest.server}${rest.banner}/${rest.bannerObterInterno}${entidade.uuid}`}
                                    preview={true} loading={"lazy"}
                                    alt="Image" width="250" height="140"/>
                            </>
                        )}
                    </div>
                </Fieldset>

                <br/>

                <Combo values={restaurantes} id={"restaurante"} filter={true}
                       optionLabel={"nomeEstabelecimento"}
                       placeHolder={"Selecione um restaurante"}
                       onChangeHandler={entidadeOnChangeHandler}
                       label={"* Restaurante"} value={entidade.restaurante}/>

                <Calendario id={"dataInicial"} label={"* Início da campanha"}
                            minDate={new Date()}
                            onChangeHandler={entidadeOnChangeHandler}
                            disabled={isDesabilitarAoEditar()}
                            value={entidade.dataInicial}/>

                <Calendario id={"dataFinal"} label={"* Fim da campanha"}
                            minDate={new Date()}
                            onChangeHandler={entidadeOnChangeHandler}
                            disabled={isDesabilitarAoEditar()}
                            value={entidade.dataFinal}/>

                <div className={`${styles.totalPagar} field grid`}>
                    <label className={` col-fixed`}
                           style={{width: "150px"}}>Total</label>
                    <div className="col">
                        {entidade.valor?.toLocaleString("pt-BR", {
                            style: 'currency',
                            currency: 'BRL'
                        })}
                    </div>
                </div>

            </EditView>
        </>
    );
}

export default BannerEdit;