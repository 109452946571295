import {Entidade} from "./Entidade";
import {BannerTipo} from "./BannerTipo";
import Restaurante from "./Restaurante";

export default class Banner extends Entidade {

    restaurante?: Restaurante;
    tipo?: BannerTipo = BannerTipo.INTERNO;
    tituloFraseExterno?: string;
    fraseExterno?: string;
    dataInicial?: Date;
    dataFinal?: Date;
    valor?: number = 0.00;

}