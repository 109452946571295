import React, {CSSProperties, FC, KeyboardEvent, useCallback} from 'react';
import {InputText} from "primereact/inputtext";
import DinheiroUtils from "../../../utils/DinheiroUtils";

interface Props {
    id?: string;
    className?: string;
    max?: number;
    onValueChange: (value: number, valueCorrect?: number) => void;
    style?: CSSProperties;
    value: number;
    disabled?: boolean;
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput: FC<Props> = ({
                                      id,
                                      className = '',
                                      max = Number.MAX_SAFE_INTEGER,
                                      onValueChange,
                                      style = {},
                                      value,
                                      disabled
                                  }) => {

    value = Math.round(value * 100);
    const valueAbsTrunc = Math.trunc(Math.abs(value));

    if (value !== valueAbsTrunc || !Number.isFinite(value) || Number.isNaN(value)) {
        throw new Error(`Valor não é do tipo Number`);
    }

    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>): void => {
            const { key, keyCode } = e;
            if (
                (value === 0 && !VALID_FIRST.test(key)) ||
                (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
            ) {
                return;
            }

            const valueString: string = value.toString();
            let nextValue: number;

            if (keyCode !== DELETE_KEY_CODE) {
                const nextValueString: string = value === 0 ? key : `${valueString}${key}`;
                nextValue = Number.parseInt(nextValueString, 10);
            } else {
                const nextValueString = valueString.slice(0, -1);
                nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
            }

            if (nextValue > max) {
                return;
            }

            const valueFormatado: string = DinheiroUtils.formatToDinheiroParaCurrencyInput(nextValue);
            const novoNextValue: number = DinheiroUtils.formatFromDinheiro(valueFormatado);

            onValueChange(nextValue, novoNextValue);
        },
        [max, onValueChange, value]
    );

    const handleChange = useCallback(() => {
        // DUMMY TO AVOID REACT WARNING
    }, []);

    const valueDisplay: string = DinheiroUtils.formatToDinheiroParaCurrencyInput(value);

    return (
        <InputText
            id={id}
            className={className}
            keyfilter="num"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            style={style}
            disabled={disabled}
            value={valueDisplay}
        />
    );
};

export default CurrencyInput;