import * as React from "react";
import {forwardRef} from "react";

import styles from './MoedaBancario.module.css';
import MoedaBancarioProps from './MoedaBancarioProps';
import CurrencyInput from "./CurrencyInput";

const MoedaBancario = forwardRef((props: MoedaBancarioProps, inputRef?: any) => {
    return (
        <>
            <div className={`${styles.espacamento} p-fluid grid`}>
                <div className={props.fullSize ? "field col-12 md:col-12": "field col-12 md:col-4"}>
                    <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                    <span>
                        <CurrencyInput id={props.id} value={props.value ? props.value : 0}
                                       aria-describedby={`${props.id}-message`}
                                       max={props.maxLength} disabled={props.disabled}
                                       className={"p-inputtext p-component"}
                                       style={{width: `${props.size}`}}
                                       onValueChange={props.onValueChangeHandler}/>
                    </span>
                </div>
            </div>
        </>
    );
});

export default MoedaBancario;