import * as React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import {PrimeReactProvider} from 'primereact/api';

import Menu from './template/Menu';
import Footer from './template/Footer';
import Conteudo from './template/Conteudo';
import Container from './template/Container'
import Header from "./template/Header";
import Bemvindo from "./view/bemvindo/Bemvindo";
import TipoRestauranteList from "./view/tiporestaurante/TipoRestauranteList";
import TipoRestauranteEdit from "./view/tiporestaurante/TipoRestauranteEdit";
import {MensagemProvider} from "./context/MensagemContext";
import DiferencialList from "./view/diferencial/DiferencialList";
import DiferencialEdit from "./view/diferencial/DiferencialEdit";
import TipoServicoList from "./view/tiposervico/TipoServicoList";
import TipoServicoEdit from "./view/tiposervico/TipoServicoEdit";
import RestauranteList from "./view/restaurante/RestauranteList";
import RestauranteEdit from "./view/restaurante/RestauranteEdit";
import RestauranteImagemEdit from "./view/restaurante/RestauranteImagemEdit";
import RestauranteCardapioComidaImagemEdit from "./view/restaurante/RestauranteCardapioComidaImagemEdit";
import UsuarioList from "./view/usuario/UsuarioList";
import UsuarioEdit from "./view/usuario/UsuarioEdit";
import Login from "./view/login/Login";
import useToken from "./hook/useToken";
import SemPermissao from "./view/erro/SemPermissao";
import PerfilEdit from "./view/perfil/PerfilEdit";
import Atendimento from "./view/atendimento/Atendimento";
import RestauranteCardapioComidaEdit from "./view/restaurante/RestauranteCardapioComidaEdit";
import RestauranteCardapioBebidaEdit from "./view/restaurante/RestauranteCardapioBebidaEdit";
import RestauranteCardapioComidaList from "./view/restaurante/RestauranteCardapioComidaList";
import RestauranteCardapioBebidaList from "./view/restaurante/RestauranteCardapioBebidaList";
import RestauranteCardapioBebidaImagemEdit from "./view/restaurante/RestauranteCardapioBebidaImagemEdit";
import DiariaList from "./view/diaria/DiariaList";
import DiariaEdit from "./view/diaria/DiariaEdit";
import BannerList from "./view/banner/BannerList";
import BannerEdit from "./view/banner/BannerEdit";

const App = () => {
    const {token, setToken} = useToken();

    if (!token) {
        return <Login setToken={setToken}/>;
    }

    if (token && !token.token) {
        return <Login setToken={setToken}/>;
    }

    return (
        <PrimeReactProvider value={{}}>
            <MensagemProvider>
                <Router>
                    <Header/>
                    <Menu/>

                    <Container customClass="minHeight">
                        <Conteudo>
                            <>
                                <Routes>
                                    <Route path='/' element={<Bemvindo/>}/>
                                    <Route path='/sem-permissao' element={<SemPermissao/>}/>

                                    <Route path='/tipo-restaurante' element={<TipoRestauranteList/>}/>
                                    <Route path='/tipo-restaurante/novo' element={<TipoRestauranteEdit/>}/>
                                    <Route path='/tipo-restaurante/editar/:uuid' element={<TipoRestauranteEdit/>}/>

                                    <Route path='/diferencial' element={<DiferencialList/>}/>
                                    <Route path='/diferencial/novo' element={<DiferencialEdit/>}/>
                                    <Route path='/diferencial/editar/:uuid' element={<DiferencialEdit/>}/>

                                    <Route path='/tipo-servico' element={<TipoServicoList/>}/>
                                    <Route path='/tipo-servico/novo' element={<TipoServicoEdit/>}/>
                                    <Route path='/tipo-servico/editar/:uuid' element={<TipoServicoEdit/>}/>

                                    <Route path='/banner' element={<BannerList/>}/>
                                    <Route path='/banner/novo' element={<BannerEdit/>}/>
                                    <Route path='/banner/editar/:uuid' element={<BannerEdit/>}/>

                                    <Route path='/diaria' element={<DiariaList/>}/>
                                    <Route path='/diaria/novo' element={<DiariaEdit/>}/>
                                    <Route path='/diaria/editar/:uuid' element={<DiariaEdit/>}/>

                                    <Route path='/restaurante' element={<RestauranteList/>}/>
                                    <Route path='/restaurante/novo' element={<RestauranteEdit/>}/>
                                    <Route path='/restaurante/editar/:uuid' element={<RestauranteEdit/>}/>
                                    <Route path='/restaurante/imagem/:uuid' element={<RestauranteImagemEdit/>}/>

                                    <Route path='/restaurante/cardapio/comida' element={<RestauranteCardapioComidaList/>}/>
                                    <Route path='/restaurante/cardapio/comida/novo' element={<RestauranteCardapioComidaEdit/>}/>
                                    <Route path='/restaurante/cardapio/comida/editar/:uuid' element={<RestauranteCardapioComidaEdit/>}/>
                                    <Route path='/restaurante/cardapio/comida/imagem/:uuid'
                                           element={<RestauranteCardapioComidaImagemEdit/>}/>

                                    <Route path='/restaurante/cardapio/bebida' element={<RestauranteCardapioBebidaList/>}/>
                                    <Route path='/restaurante/cardapio/bebida/novo' element={<RestauranteCardapioBebidaEdit/>}/>
                                    <Route path='/restaurante/cardapio/bebida/editar/:uuid' element={<RestauranteCardapioBebidaEdit/>}/>
                                    <Route path='/restaurante/cardapio/bebida/imagem/:uuid'
                                           element={<RestauranteCardapioBebidaImagemEdit/>}/>

                                    <Route path='/usuario' element={<UsuarioList/>}/>
                                    <Route path='/usuario/novo' element={<UsuarioEdit/>}/>
                                    <Route path='/usuario/editar/:uuid' element={<UsuarioEdit/>}/>

                                    <Route path='/perfil' element={<PerfilEdit/>}/>
                                    <Route path='/atendimento' element={<Atendimento/>}/>
                                </Routes>
                            </>
                        </Conteudo>
                    </Container>

                    <Footer/>
                </Router>
            </MensagemProvider>
        </PrimeReactProvider>
    );
}

export default App;
