import * as React from "react";
import {forwardRef} from "react";

import styles from './Botao.module.css';
import BotaoProps from './BotaoProps';
import {Button} from "primereact/button";
import {Tooltip} from "primereact/tooltip";

const Botao = forwardRef((props: BotaoProps, inputRef?: any) => {
    return (
        <>
            <div className={`${styles.espacamento}`} style={{margin: 0}}>
                <div className="flex flex-row flex-wrap">
                    <Button id={props.id} label={props.label}
                            icon={props.icon}
                            size={props.size}
                            style={props.style}
                            loading={props.loading}
                            onClick={props.onClickHandler}/>

                    {props.hint && (
                        <>
                            <Tooltip target=".pi-exclamation-circle" />
                            <i className="pi pi-exclamation-circle"
                               data-pr-tooltip={props.hint}
                               data-pr-position="right"
                               data-pr-at="right+5 top+20"
                               data-pr-my="left center-2"
                               style={{fontSize: '2rem', color: "darkorange", paddingLeft: '5px', paddingTop: '6px'}}>
                            </i>
                        </>
                    )}
                </div>

            </div>
        </>
    );
});

export default Botao;