import * as React from "react";
import {forwardRef} from "react";

import styles from './Combo.module.css';
import ComboProps from './ComboProps';
import {Dropdown} from "primereact/dropdown";

const Combo = forwardRef((props: ComboProps, inputRef?: any) => {
    return (
        <>
            <div className={`${styles.espacamento} p-fluid grid`}>
                <div className="field col-12 md:col-4">
                    <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                    <span>
                        <Dropdown id={props.id}
                                  value={props.value}
                                  optionLabel={props.optionLabel}
                                  optionValue={props.optionValue}
                                  options={props.values}
                                  filter={props.filter}
                                  checkmark={true} highlightOnSelect={false}
                                  placeholder={props.placeHolder}
                                  disabled={props.disabled}
                                  onChange={props.onChangeHandler} />
                    </span>
                </div>
            </div>
        </>
    );
});

export default Combo;