import * as React from "react";

import {useNavigate} from 'react-router-dom';

import {MegaMenu} from 'primereact/megamenu';

import styles from './Menu.module.css';
import useToken from "../hook/useToken";
import {TipoUsuario} from "../model/TipoUsuario";

function Menu() {
    const {token, setToken} = useToken();
    const navigate = useNavigate();
    const menuItensAdmin = [
        {label: 'Início', icon: 'pi pi-home', command: () => { navigate("/") }},
        {label: 'Tipo restaurante', icon: 'pi pi-briefcase', command: () => { navigate("/tipo-restaurante") }},
        {label: 'Diferencial', icon: 'pi pi-calendar-clock', command: () => { navigate("/diferencial") }},
        {label: 'Tipo serviço', icon: 'pi pi-hammer', command: () => { navigate("/tipo-servico") }},
        {label: 'Banner', icon: 'pi pi-image', command: () => { navigate("/banner") }},
        {label: 'Diária', icon: 'pi pi-hammer', command: () => { navigate("/diaria") }},
        {label: 'Restaurante', icon: 'pi pi-building-columns', command: () => { navigate("/restaurante") }},
        {label: 'Perfil', icon: 'pi pi-address-book', command: () => { navigate("/perfil") }},
        {label: 'Usuário', icon: 'pi pi-user', command: () => { navigate("/usuario") }},
        {label: '', icon: 'pi pi-sign-out', command: () => {
                setToken();
                window.location.reload();
        }},
    ];
    const menuItensCliente = [
        {label: 'Início', icon: 'pi pi-home', command: () => { navigate("/") }},
        {label: 'Banner', icon: 'pi pi-image', command: () => { navigate("/banner") }},
        {label: 'Diária', icon: 'pi pi-hammer', command: () => { navigate("/diaria") }},
        {label: 'Restaurante', icon: 'pi pi-building-columns', command: () => { navigate("/restaurante") }},
        {label: 'Perfil', icon: 'pi pi-address-book', command: () => { navigate("/perfil") }},
        {label: 'Atendimento', icon: 'pi pi-question-circle', command: () => { window.open("https://atendimento", "_blank") }},
        {label: '', icon: 'pi pi-sign-out', command: () => {
                setToken();
                window.location.reload();
            }},
    ];

    return (
        <div className={`${styles.espacamento}`}>
            {token.tipo && token.tipo === TipoUsuario.ADMINISTRADOR && (
                    <MegaMenu model={menuItensAdmin} orientation={"horizontal"}
                              breakpoint="960px" className={`${styles.tamanhoMenuItem}`} />
            )}

            {token.tipo && token.tipo === TipoUsuario.CLIENTE && (
                <MegaMenu model={menuItensCliente} orientation={"horizontal"}
                          breakpoint="960px" className={`${styles.tamanhoMenuItem}`} />
            )}
        </div>
    );
}

export default Menu;